.nav-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.nav-link {
  color: rgb(81, 81, 81);
  font-family: "Jaldi", sans-serif;
  font-size: 1rem;
  text-decoration: none;
  margin: 2rem 0.5rem 1rem 0.5rem;
  font-weight: 400;
  letter-spacing: 1px;
  position: relative;
  display: block;
  transition: 0.35s;
  transform: scale(1, 0.9);
  cursor: pointer;
}

.nav-link::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 100%;
  height: 1.1px;
  background: rgb(180, 180, 180);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s;
}

.active {
  color: #000;
  font-weight: 500;
  letter-spacing: 0;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-heading div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

@media screen and (max-width: 530px) {
  .nav-heading {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  .nav-link {
    margin: 0.3rem;
    margin-left: 0rem;
    font-size: 0.95rem;
  }
  .nav-link::after {
    background: #fff;
  }
  .active {
    text-decoration: underline;
  }
}
