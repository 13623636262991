.about--heading,
.skills--heading {
  margin: 1.2rem 0 0 0;
  color: transparent;
  background: linear-gradient(
    360deg,
    rgb(55, 221, 91) 20%,
    rgb(96, 199, 247) 40%,
    rgb(141, 136, 251) 100%
  );
  -webkit-background-clip: text;

  font-weight: 800;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.about--heading span {
  font-size: 1rem;
}

.about--text {
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.45px;
  list-style-type: none;
  text-align: left;
  background: #fff;
  color: rgb(57, 57, 57);
  margin: 0.8rem 1rem 1.8rem 1rem;
}
.skill {
  margin-top: 1.7rem;
}

.skills--heading {
  margin-bottom: 0.8rem;
}

.skills--heading span {
  font-size: 1rem;
  letter-spacing: 0.9px;
}

.section {
  border: 1px solid rgb(229, 229, 229);
  border-radius: 15px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "fr ba"
    "de de";
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.main-heading {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1.2rem;
}

.front {
  padding: 2rem;
  grid-area: fr;
  border-right: 1px solid rgb(229, 229, 229);
}
.back {
  padding: 2rem;
  grid-area: ba;
}
.deploy {
  padding: 2rem;
  grid-area: de;
  border-top: 1px solid rgb(229, 229, 229);
}

.front > div,
.back > div,
.deploy > div {
  text-align: center;
}

.deploy > div {
  display: flex;
  justify-content: space-evenly;
}

.front div > ul,
.back div > ul {
  padding-bottom: 1.5rem;
}
.front div > ul:last-child,
.back div > ul:last-child,
.deploy div > ul:last-child {
  padding-bottom: 0;
}

.heading {
  font-size: 1.05rem;
  font-weight: 400;
  color: transparent;
  background: linear-gradient(
    120deg,
    rgb(117, 110, 252) 0%,
    rgb(73, 193, 249) 50%
  );
  -webkit-background-clip: text;
}

.front .list,
.back .list,
.deploy .list {
  list-style: none;
  margin: 0.5rem 0 0 0;
}

.list li {
  font-size: 1rem;
  font-weight: 400;
  color: rgb(57, 57, 57);
}

@media only screen and (max-width: 630px) {
  .section {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "fr"
      "ba"
      "de";
  }
  .main-heading {
    font-size: 1.1rem;
  }
  .front {
    padding: 2.5rem 1rem;
    border: none;
    border-bottom: 1px solid gray;
  }
  .back {
    padding: 2.5rem 1rem;
  }
  .deploy {
    padding: 2.5rem 1rem;
  }
  .deploy > div {
    flex-direction: column;
  }
}

@media screen and (max-width: 530px) {
  .skills-container {
    grid-template-columns: auto;
  }
  .about--text {
    font-size: 0.95rem;
    margin: 0.4rem 0 0.6rem 0;
  }
}
