.form-container {
  text-align: center;
  margin: 1rem 0;
  padding: 1rem;
}

h2 {
  font-family: "Lato", sans-serif;
  color: transparent;
  background: linear-gradient(
    360deg,
    rgb(55, 221, 91) 20%,
    rgb(96, 199, 247) 40%,
    rgb(141, 136, 251) 100%
  );
  -webkit-background-clip: text;
}

.form-container h2 {
  margin: 0.5rem 0 2.5rem 0;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  background: #fff;
}

.form-field {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-field label {
  margin-bottom: 0.15rem;
  font-size: 0.9rem;
}

.input,
.msg-input {
  margin-bottom: 1.5rem;
  border: none;
  border-bottom: 1px solid rgb(166, 166, 166);
  font-size: 0.95rem;
  outline: none;
  font-family: "Lato", sans-serif;
}

.input {
  height: 30px;
}

.form-btn {
  width: 120px;
  height: 40px;
  margin: 0 auto;
  background: none;
  border: 0.7px solid transparent;
  border-image: linear-gradient(
    360deg,
    rgb(22, 170, 54) 20%,
    rgb(66, 179, 231) 40%,
    rgb(108, 102, 236) 100%
  );
  border-image-slice: 1;
  font-size: 0.95rem;
  border-image-outset: 1px;
  color: #000;
  cursor: pointer;
}
.form-btn:hover {
  background-image: linear-gradient(
    360deg,
    rgb(130, 244, 155) 20%,
    rgb(140, 217, 253) 40%,
    rgb(161, 157, 240) 100%
  );
}

.social-contact {
  margin-top: 4rem;
}

.social-contact h2 {
  margin-bottom: 1rem;
}

.social-contact a {
  font-size: 2rem;
  margin-right: 1rem;
  color: rgb(94, 92, 92);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: rgb(95, 94, 94);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 0.8rem;
  top: 70%;
  left: 55%;
  margin-left: -20px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
