* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  /* overflow-x: hidden; */
}
