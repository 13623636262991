.card-details {
  margin-bottom: 0.8rem;
}

.cards-heading {
  text-align: left;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  color: transparent;
  background: linear-gradient(
    360deg,
    rgb(55, 221, 91) 20%,
    rgb(96, 199, 247) 40%,
    rgb(141, 136, 251) 100%
  );
  -webkit-background-clip: text;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

li {
  font-family: "Lato", sans-serif;
}

.cards-heading span {
  font-size: 1.05rem;
  letter-spacing: 0.45px;
}

.cards-container {
  margin: 0 1rem;
}

.card-heading {
  font-size: 1.1rem;
  letter-spacing: 0.25px;
  font-weight: 600;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
}

.card-text,
li {
  font-size: 1.05rem;
  line-height: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.45px;
  margin: 0;
  color: rgb(57, 57, 57);
  background: #fff;
  z-index: 6;
}
.card-text a {
  font-size: 0.95rem;
  color: grey;
  text-decoration: none;
}

.card-text a:hover {
  color: rgb(57, 57, 57);
}

.card-img-container {
  width: 100%;
  margin-bottom: 2rem;
}
.cards-container > ol {
  margin-left: 1rem;
  margin-right: 1rem;
}

@media only screen and (max-width: 530px) {
  .cards-heading {
    margin-bottom: 0.6rem;
    margin-top: 1.6rem;
  }
  .cards-container {
    margin: 0;
  }

  .card-heading {
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }

  .card-text {
    font-size: 0.95rem;
    margin: 0.3rem 0 0.6rem 0;
  }
}
