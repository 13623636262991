.carousel {
  position: relative;
  max-width: fit-content;
}

.slider-btn {
  position: absolute;
  height: 35px;
  width: 35px;
  padding-top: 0.2rem;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.8px solid green;
  cursor: pointer;
  font-size: 1rem;
  color: #fff;
}

.slider-btn-1 {
  top: 50%;
  left: 3%;
  transform: translate(0, -50%);
}

.slider-btn-2 {
  top: 50%;
  right: 3%;
  transform: translate(0, -50%);
}

.card-img {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  object-fit: cover;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.img-link {
  position: absolute;
  right: 0;
  width: 35px;
  height: 35px;
  border: 0.7px solid green;
  border-radius: 50%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  top: 0.4rem;
  right: 0.4rem;
  /* margin-top: 0.3rem;
  margin-right: 0.3rem; */
}

.img-link div {
  text-align: center;
  font-size: 0.75rem;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 600px) {
  .slider-btn {
    height: 25px;
    width: 25px;
    font-size: 0.6rem;
  }
  .img-link {
    height: 25px;
    width: 25px;
  }
  .img-link div {
    font-size: 0.55rem;
  }
}
